export const CHIRA_CEO_URL = 'https://onlystory.co.jp/service/'
export const CHIRA_CEO_CASE_URL = `${CHIRA_CEO_URL}/case`
export const EVENTS_URL =
  'https://onlystory.co.jp/service/seminar_event_c/event/'
export const APP_LP_URL = 'https://onlystory.co.jp/service/'
export const APP_REQUEST_INFORMATION_URL = 'https://onlystory.co.jp/'
export const HIRING_URL =
  'https://www.wantedly.com/companies/onlystory?_fsi=QBBBg4lm'
export const NOTE_URL = 'https://note.com/onlystory_hirano?_fsi=R1sl8ISN'

export const FORGOT_ID_AND_PASSWORD_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSf6UEYIee9UZ9MttyjfFoRXcXmHLSLc4qEm_Iav3--rs4HpbQ/viewform'
export const PAID_PLAN_CONTACT_URL = 'https://onlystory.co.jp/service/contact/'

export const FREE_COMPANY_ALERT_URL =
  'https://note.com/preview/nb09030d2aeb6?prev_access_key=0029f79ac199f60e35b9a4193ca1bb63'

export const KNOWLEDGE_BAND_URL =
  'https://apps.apple.com/jp/app/%E3%83%8A%E3%83%AC%E3%83%83%E3%82%B8%E3%83%90%E3%83%B3%E3%83%89/id6450103578'

export const SERVICE_EXPLAIN_URL =
  'https://drive.google.com/file/d/1_53aMgHkC4Z8LIlws-V_uTNxsStF9fRd/view?usp=drive_link'

export const PAID_MODAL_TIMEREX_URL = 'https://timerex.net/s/ChiraCEO/0c4c276d'

export const GUIDE_LINK =
  'https://docs.google.com/presentation/d/1lfG6gl3NICe5yui_TnACL4Aq_aVZ6THZ/edit?usp=sharing&ouid=114120494834327925868&rtpof=true&sd=true'
