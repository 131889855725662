import { VFC } from 'react'
import { createGlobalStyle, css } from 'styled-components'
import { black } from './colors'
import { fontFamily, fontWeightRegular } from './font'

export const GlobalStyles: VFC<StylesProps> = (props) => {
  return (
    <>
      <Styles {...props} />
    </>
  )
}

type StylesProps = { skipAnimation?: boolean }
const Styles = createGlobalStyle<StylesProps>`
  img {
    vertical-align: bottom;
  }
  input,
  textarea {
    overflow: hidden;
    text-overflow: ellipsis;
    ::placeholder {
      opacity: 0.5;
    }
  }
  html {
    height: 100%;
  overflow: overlay;
    * {
      ::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
      }
      ::-webkit-scrollbar-track {
      }
      ::-webkit-scrollbar-thumb {
        background-color: #cdcdcd;
        border-radius: 2px;
      }
    }
  }
  div {
    :focus{
      outline: none;
    }
  }
  body {
    font-family: ${fontFamily};
    font-weight: ${fontWeightRegular};
    font-size: 14px;
    color: ${black};
    height: 100%;
    line-break: strict;
    &, header {
      // for src/components/Header/NarrowHeader/GuestNarrowHeader.tsx
      transition: 0.5s ease-in-out;
      transition-property: margin-left margin-right;
    }
    
    > div#__next,
    // storybook
    > div#root {
      height: 100%;
      display: flex;
      flex-flow: column;
      main {
        flex: 1;
      }
    }
    a {
      cursor: pointer;
    }
    button {
      :disabled {
        cursor: not-allowed;
      }
    }
    ${({ skipAnimation }) =>
      skipAnimation &&
      css`
      *,
      *::before,
      *::after {
        transition-duration: 0.01ms !important;
        animation-duration: 0.01ms !important;
      `}
  }
`
