import Router, { NextRouter } from 'next/router'
import { parse, stringify } from 'qs'
import { PickByValueExact } from 'utility-types'
import { filterByType } from '../array'
import { filterFalsy } from '../object'
import { isClient, isString } from '../typeguard'
import * as ClientPaths from './clientPaths'

const INTERNAL_ROUTES = filterByType(Object.values(ClientPaths), isString)

const ID_PLACE_HOLDER = '-9999'
const ID_PATTERN = '[a-zA-Z0-9-]+'
const NO_SLASH = '(?!.*/)'
const QUERY_PATTERN = '(\\?|\\?.+&)'

const idPaths: PickByValueExact<
  typeof ClientPaths,
  (id: number | string) => string
> = {
  greet: ClientPaths.greet,
  contributionDetail: ClientPaths.contributionDetail,
  editContribution: ClientPaths.editContribution,
  eventDetail: ClientPaths.eventDetail,
}

const idPathsToPatterns = (args: ((id: number) => string)[]) =>
  args.map(
    (arg) =>
      arg(+ID_PLACE_HOLDER)
        .replace(ID_PLACE_HOLDER, ID_PATTERN)
        .replace('?', QUERY_PATTERN) + NO_SLASH,
  )
const INTERNAL_ROUTES_PATTERN = new RegExp(
  `(?:${[...idPathsToPatterns(Object.values(idPaths))].join('|')})`,
)

export const isClientPath = (path: string) =>
  INTERNAL_ROUTES.includes(extractPathname(path)) ||
  INTERNAL_ROUTES_PATTERN.test(path)

export const extractPathname = (path: string = '') => path.split(/[?#]/)[0]

export const query = (
  path: string,
  queryParam: any = {},
): Parameters<typeof Router['push']>[0] => {
  const filteredParam = filterFalsy(queryParam)
  if (!Object.keys(filteredParam).length) return path
  return { query: filteredParam }
}
export const shouldNoIndex = (path: string) =>
  path.startsWith(ClientPaths.MYPAGE)

export const previousPath = () => {
  if (!isClient()) return ''
  try {
    const url = new URL(document.referrer)
    return url.pathname
  } catch {
    return ''
  }
}

export const NO_AUTH_PATHS = [
  ClientPaths.SORRY,
  ClientPaths.AUTH_FACEBOOK,
  ClientPaths.FORGOT_PASSWORD,
  ClientPaths.SIGN_IN,
  ClientPaths.FACEBOOK_DELETION,
  ClientPaths.APPLY_NOW,
  ClientPaths.REGISTER,
  ClientPaths.REGISTER_COMPLETE,
  ClientPaths.MESSAGE_GUEST,
]

// NOTE: router.query からメッセージ画面で使用する query を抽出します
export const convertMessagePageQuqry = (
  query: NextRouter['query'],
): messagePageQueryType => {
  return {
    room_id: query.room_id,
    room_name: query.room_name,
    visibility_status: query.visibility_status,
    unread_only: query.unread_only,
    has_memo: query.has_memo,
    matching_status: query.matching_status,
    sent_request_from_search: query.sent_request_from_search,
    recieved_request_from_search: query.recieved_request_from_search,
    sent_request_from_request_box: query.sent_request_from_request_box,
    recieved_request_from_request_box: query.recieved_request_from_request_box,
    sent_request_from_contribution: query.sent_request_from_contribution,
    recieved_request_from_contribution:
      query.recieved_request_from_contribution,
    creation_source_is_referrer: query.creation_source_is_referrer,
    creation_source_is_event: query.creation_source_is_event,
    requested_from_other: query.requested_from_other,
    tag_ids: query.tag_ids,
    room_type: query.room_type,
  }
}

export type messagePageQueryType = {
  room_id: queryType
  room_name: queryType
  visibility_status: queryType
  unread_only: queryType
  has_memo: queryType
  matching_status: queryType
  sent_request_from_search: queryType
  recieved_request_from_search: queryType
  sent_request_from_request_box: queryType
  recieved_request_from_request_box: queryType
  sent_request_from_contribution: queryType
  recieved_request_from_contribution: queryType
  creation_source_is_referrer: queryType
  creation_source_is_event: queryType
  requested_from_other: queryType
  tag_ids: queryType
  room_type: queryType
}
export type queryType = string | string[] | null | undefined
type toMessagePageQueryProps = {
  pathname: string
  query: messagePageQueryType
}
// NOTE: query 更新時の型を共通化するための関数です
// 引数 に query を渡したいが filter_type に null を代入する処理などがあり難しい
export const toMessagePageQuery = (
  params: toMessagePageQueryProps,
): toMessagePageQueryProps => ({
  pathname: params.pathname,
  query: {
    ...params.query,
  },
})

type stringifyQueryType = { [key in string]: (number | string)[] }
export const stringifyQueryToComma = (query: stringifyQueryType) =>
  stringify(query, { arrayFormat: 'comma' })

export const convertTagIds = (value: queryType): number[] => {
  if (typeof value !== 'string') return []

  const parsedQuery = parse(value, { comma: true })
  const tagIdsStr = parsedQuery.tag_ids
  if (typeof tagIdsStr !== 'string') return []

  const tagIdsArr = tagIdsStr.split(',')
  const tagIds = tagIdsArr.map((id) => Number(id))
  return tagIds
}
