export const trueblack = '#000000'
export const black = '#393e4c'
export const orange = '#ff6c00'
export const pink = '#eb2562'
export const safetyOrange = '#ff6c01'
export const softOrange = '#fef6ef'
export const lightorange = '#ff8a34'
export const paleorange = '#fdeee3'
export const lightgray = '#a5a5a5'
export const mediumGray = '#606060'
export const darkgray = '#3a3a3a'
export const bordergray = '#dddddd'
export const white = '#f7f7f7'
export const floralwhite = '#faf8f4'
export const truewhite = '#ffffff'
export const errorRed = '#d90a0a'
export const lightgreen = '#00d64a'
export const cautionRed = '#ff0000'

export const purchasegreen = '#53B3AE'
export const accentColor = '#02B7B0'
export const accentLightColor = '#F4FFFF'
export const defaultShadow = '3px 6px 10px #9C9DA099'

export const facebookBlue = '#1877F2'
export const facebookBlueLight = '#277ef2'
export const signoutGray = '#898989'
export const contributionGray = '#808080'
export const attentionColor = '#d83c63'

export const _menuborder = '#5d5d5d'
export const _menuicon = '#cdcdcd'
export const _menuhover = '#ab6e0c'
export const _menuorange = '#fd7415'

export const _modaloverlay = '#2b2e38'
export const _modalunderline = '#0a0a0a'

export const _membertopbg = '#f2f3f7'
export const _membertopborder = '#c0c0c0'

export const _profilemenubg = '#393e4c'
export const _profilehrborder = '#eeeeee'
export const _profilegreen = '#02b7b0'
export const _profilegray = '#bbbbbb'
export const _profileoverlay = '#c8c8c8'

export const _reasonoverlay = '#c8c8c8'
export const _reasongreen = '#02b7b0'

export const _telborder = '#cccccc'

export const _cardgradient = `linear-gradient(150deg, ${black}, #6b6f79)`
export const _cardsubtitle = '#bebebe'
export const _cardinnershadow = '1px 2px 8px #d4d4d4'
export const _contributionborder = '#80dbd7'
export const _contributiontext = '#02b7b0'
export const _inversingbuttonshadow = '0 3px 6px #c4c4c6'
export const _contributionmenubuttongradient = `linear-gradient(150deg, ${_contributionborder}, #0286b7)`
export const _contributionsidebarshadow = '3px 0 4px -4px #999999'
export const _contributionssearchtitle = '#bbbbbb'
export const _contributionssearchborder = '#e6e6e6'
export const _contributionssearchShadow = '0 3px 4px -4px #999999'
export const _contributionsTextarea = '#d6d8da'
export const _contributionsBackground = '#f8fafc'
export const _contributionsSpecialtyLabel = '#e4e4e4'
export const _contributionsLabelBackground = '#F3FBFA'
export const _contributionsPremiumBackground = '#FCF8F1'
export const _contributionPremiumDetailValue = '#A8A9AC'
export const _contributionPremiumBorder = '#E8E6E6'

export const _filterbg = '#cdcdcd'
export const _filterborder = '#80dbd7'
export const _filtertext = '#02b7b0'

export const _messagesnavshadow = '3px 0 6px #393e4c14'
export const _messagesnavbg = '#ffffff40'
export const _messageroomborder = '#f4f4f4'

export const _searchbg = '#F8F8F8'
export const _messagesbg = '#f7f9fc'
export const _messagebg = '#ffffff'
export const _mymessagebg = '#02b7b0'
export const _mymessage = '#ffffff'
export const _messageformborder = '#e2e2e2'
export const _messageformbg = '#ffffff'
export const _messagebutton = '#ffffff'
export const _messagebuttonbg = '#02b7b0'
export const _messagecancelbutton = '#B2B2B2'
export const _messageiconfill = '#02b7b0'
export const _messagesecondary = '#cdcdcd'
export const _roomactivebg = '#f7f9fc'
export const _guidemessagebg = '#43c59e'
export const _message_submenu = '#808080'
export const _message_submenu_subtitle = '#A8A9AC'
export const _message_tag_label = '#e4e4e4'
export const _tags_management_background = '#f6f6f6'
export const _unreadbatchmessage = '#02b7b0'

export const _messagetemplateborder = '#efefef'
export const _messagetemplatebuttondisabled = '#cdcdcd'
export const _messagetemplatebuttonsaved = '#DEFFFE'
export const _greetsecondary = '#A09FA5'
export const _greetborder = '#efefef'

export const _tooltipbg = '#393E4C'
export const _tooltip = '#FFFFFF'
export const _tooltipshadow = '3px 6px 10px #cccccc'
export const _tooltiphover = '#02b7b0'
export const _popupbg = '#ffffff'

export const _feedbacksecondary = '#a19c9f'
export const _feedbacknote = '#02b7b0'
export const _feedbackstar = '#F8A12E'

export const _appointmentbuttonbg1 = '#ff6c00'
export const _appointmentbuttonbg1hovered = '#ffb076'
export const _appointmentbuttonbg2hovered = '#7be8e4'
export const _appointmentbuttonbg3 = '#767676'
export const _appointmentbuttonbg3hovered = '#afafaf'
export const _appointmentbuttonbgsales = '#9c74fc'

export const _formplaceholdertext = '#bbbbbb'
export const _forminputback = '#f8f8f8'
export const _formborder = '#dedede'
export const _formalert = '#ff2626'

export const _contributionbuttonpurchase = '#c84964'

export const _paidModalPlanText = '#A8A9AC'
export const _paidModalPlanTitle = '#606060'
export const _paidModalMonitorPlan = '#3ab7b0'

export const _timelineDate = '#5b5b5b'
export const _timelineMessage = '#FFF3F3'
export const _timelineMessageText = '#d40000'
export const _timelineMessageTitle = '#d41f00'
export const _timelineRequestReason = '#8C8C8C'
export const _timelineRequestedUser = '#fef6fc'
export const _timelineRequestedUserTitle = '#e02694'
export const _timelineRecommendedUser = '#eff3ff'
export const _timelineRecommendedUserTitle = '#264cd1'
export const _timelineNewUser = '#eefffe'
export const _timelineNewUserTitle = '#269990'
export const _timelineInfo = '#383e4c'
export const _timelineContribution = '#f5fff6'
export const _timelineContributionTitle = '#24a110'
export const _timelinePremiumContribution = '#fef9ea'
export const _timelinePremiumContributionTitle = '#e4a400'
export const _timelineEvent = '#F7F3FF'
export const _timelineEventTitle = '#3a15b7'
export const _timelineBatchMessage = '#e3224c'
export const _feedback_red = '#e84714'
export const _feedback_yellow = '#f29601'
export const _feedback_blue = '#2688CC'
export const _feedback_orange = '#f96c01'
export const _feedback_has_violation = '#f1961a'

export const _feedback_modal_impression_score_bg = '#FEEAEA'

export const _requestbox_item_border = '#f0f0f0'
export const _introductory_text_border = '#e9e9e9'
export const _introductory_text_at = '#999999'
