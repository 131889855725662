import { ReactNode, useEffect, useState, VFC } from 'react'
import { CLOSE_ICON } from 'src/assets/images'
import { _modaloverlay } from 'src/styles/colors'
import { absoluteFill, flexCenter } from 'src/styles/mixins'
import { disableScroll, enableScroll } from 'src/utils/disableScroll'
import { MODAL } from 'src/utils/zIndex'
import styled, { css, SimpleInterpolation } from 'styled-components'

export type ModalProps<T = {}> = T &
  ModalState & {
    close: (_?: any, closeOption?: { immediately?: boolean }) => void
  }

export type ModalState = {
  open: boolean
}

type ModalOption = Partial<{
  overlayStyle: SimpleInterpolation
  scrollable: boolean
  inescapable: boolean
  disableDefaultAnimation: boolean
}>

type AsModalFn = <T>(
  Component: VFC<ModalProps<T>>,
  option?: ModalOption,
) => VFC<T & { close: VoidFunction }>

type ModalFrameProps = {
  children: ReactNode
  className?: string
}
export const ModalFrame: VFC<ModalFrameProps> = ({ children, className }) => (
  <ModalDiv className={className}>
    <ModalOuterDiv>{children}</ModalOuterDiv>
  </ModalDiv>
)
export const ModalFrameFlexEnd: VFC<ModalFrameProps> = ({
  children,
  className,
}) => (
  <ModalDiv className={className}>
    <ModalOuterFlexEndDiv>{children}</ModalOuterFlexEndDiv>
  </ModalDiv>
)

export const asModal: AsModalFn =
  (Component, option) =>
  ({ ...props }) => {
    const [open, setOpen] = useState(false)
    const close: ModalProps['close'] = (_, closeOption) => {
      setOpen(false)
      window.document.documentElement.removeAttribute('style')
      if (closeOption?.immediately) props.close()
    }
    useEffect(() => {
      setOpen(true)
      if (option?.scrollable) {
        window.document.documentElement.setAttribute(
          'style',
          'overflow: hidden;',
        )
        return
      }
      disableScroll()
      return enableScroll
    }, [])
    return (
      <ModalFrame>
        <ModalOverlay
          onClick={option?.inescapable ? undefined : close}
          styles={option?.overlayStyle}
          open={open}
          onTransitionEnd={!open ? props.close : undefined}
        />
        <ModalContentDiv
          shouldAnimate={!option?.disableDefaultAnimation}
          open={open}
        >
          <div>
            {!option?.inescapable && (
              <CloseButton type="button" onClick={close}>
                <img src={CLOSE_ICON} alt="閉じる" />
              </CloseButton>
            )}
            <Component {...props} close={close} open={open} />
          </div>
        </ModalContentDiv>
      </ModalFrame>
    )
  }

const ModalDiv = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${MODAL};
`

const ModalOuterDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${flexCenter};
`
const ModalOuterFlexEndDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
`
const overlayOpenStyle = css`
  background-color: ${_modaloverlay}e6;
`
const ModalOverlay = styled.div<ModalState & { styles: SimpleInterpolation }>`
  ${absoluteFill};
  transition: all 0.5s;

  ${({ open, styles }) => open && (styles || overlayOpenStyle)};
`

const contentOpenStyle = css`
  opacity: 1;
  transform: translateY(0);
`
const contentCloseStyle = css`
  opacity: 0;
  transform: translateY(-50%);
`

const ModalContentDiv = styled.div<ModalState & { shouldAnimate: boolean }>`
  position: relative;
  > div {
    transition: all 0.5s;
    ${({ shouldAnimate, open }) =>
      shouldAnimate && (open ? contentOpenStyle : contentCloseStyle)}
  }
`

const CloseButton = styled.button`
  position: absolute;
  img {
    width: 35px;
    height: 35px;
  }
  top: -30px;
  right: -30px;

  @media screen and (max-width: 960px) {
    top: -50px;
    right: 0px;
  }
`
