import { css } from 'styled-components'

export const LANDSCAPE_MIN_WIDTH = 1280

export const WIDTH_THRESHOLD = 960

export const onLandscape = (style: ReturnType<typeof css>) => css`
  @media screen and (min-width: ${LANDSCAPE_MIN_WIDTH}px) {
    ${style};
  }
`

export const landscapeOnly = css`
  display: none;
  @media screen and (min-width: ${LANDSCAPE_MIN_WIDTH}px) {
    display: unset;
  }
`
export const absoluteFill = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`

export const noScrollbar = css`
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const leftArrow = css`
  position: relative;
  ::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: -10%;
    right: 14px;
    bottom: 0;
    margin: auto 0;

    height: 4px;
    width: 12px;
    border: solid 1px;
    border-top: none;
    border-left: none;

    transform: skew(45deg);
    transition: 0.2s;
  }
  :hover {
    ::after {
      right: 10px;
    }
  }
`

export const rightCenterLine = css`
  display: flex;
  align-items: center;
  ::after {
    content: '';
    display: block;
    margin-left: 20px;
    flex: 1;
    height: 0.5px;
  }
`
export const textEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`

export const lineClamp2 = css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`

export const lineClamp3 = css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`

export const lineClamp4 = css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
`

export const lineClamp7 = css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  overflow: hidden;
`

export const inversing = ({
  color,
  background,
  inversedColor,
  inversedBackground,
  transition,
}: {
  color: string
  background: string
  inversedColor?: string
  inversedBackground?: string
  transition?: string
}) => css`
  transition: ${transition || '0.2s ease-in-out'};
  transition-property: color background-color;
  background-color: ${background};
  color: ${color};
  :hover,
  :focus {
    background: ${inversedBackground || color};
    color: ${inversedColor || background};
  }
`
export type InversingParams = Parameters<typeof inversing>[0]
