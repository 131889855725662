import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useEffect } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import TagManager from 'react-gtm-module'
import { CrashMessageModal } from 'src/components/Modal/ErrorMessageModal'
import { ModalPortal } from 'src/hooks/useModal'
import { GlobalStyles } from 'src/styles/global-styles'
import 'src/styles/reset.css'
import { GA_TRACKING_ID, GTM_ID, IS_PROD } from 'src/utils/env'
import { extractPathname, shouldNoIndex } from 'src/utils/routes'
import { SWRConfig } from 'swr'

const TimeZone = 'Asia/Tokyo'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault(TimeZone)

const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  console.error(error)
  return <CrashMessageModal close={resetErrorBoundary} />
}

const googleAnalyticsPageViewer = (url: string) => {
  ;(window as any).gtag('config', GA_TRACKING_ID, {
    page_path: url,
  })
}

const MyApp = ({ Component, pageProps, router }: AppProps) => {
  const pathname = extractPathname(router.asPath)

  // Google Tag Manager
  useEffect(() => {
    if (GTM_ID) TagManager.initialize({ gtmId: GTM_ID })
  }, [])

  // Google Analytics
  useEffect(() => {
    router.events.on('routeChangeComplete', googleAnalyticsPageViewer)
    return () => {
      router.events.off('routeChangeComplete', googleAnalyticsPageViewer)
    }
  }, [router.events])

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Head>
        <link
          rel="shortcut icon"
          href="https://assets.onlystory.co.jp/images/favicon.ico"
        />
        {(!IS_PROD || shouldNoIndex(pathname)) && (
          <meta name="robots" content="noindex" />
        )}
      </Head>
      <GlobalStyles />
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
        }}
      >
        <Component {...pageProps} router={router} />
        <ModalPortal />
      </SWRConfig>
    </ErrorBoundary>
  )
}

export default MyApp
