export const IS_DEVELOPMENT = process.env.NEXT_PUBLIC_IS_DEVELOPMENT === 'true'
export const IS_PROD = process.env.NEXT_PUBLIC_IS_PROD === 'true'

export const HOSTNAME = process.env.NEXT_PUBLIC_HOSTNAME || ''

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || ''

export const FACEBOOK_APP_ID =
  process.env.NEXT_PUBLIC_FACEBOOK_APP_ID || '1597926913870940'

export const AWS_REGION = process.env.NEXT_PUBLIC_AWS_REGION || 'ap-northeast-1'
export const AWS_COGNITO_USERPOOL_ID =
  process.env.NEXT_PUBLIC_AWS_COGNITO_USERPOOL_ID || 'ap-northeast-1_rtJALdyjL'
export const AWS_COGNITO_USERPOOL_CLIENT_ID =
  process.env.NEXT_PUBLIC_AWS_COGNITO_USERPOOL_CLIENT_ID ||
  '4v7o9vhffc6grqvo5iu1m9a8ce'
export const AWS_AUTH_DOMAIN =
  process.env.NEXT_PUBLIC_AWS_AUTH_DOMAIN || 'auth.test1.onlystory.co.jp'

export const APPSYNC_ENDPOINT =
  process.env.NEXT_PUBLIC_AWS_APPSYNC_ENDPOINT ||
  'https://vc6evl4q7vd3xdo3jhekg4w6c4.appsync-api.ap-northeast-1.amazonaws.com/graphql'

export const GA_TRACKING_ID =
  process.env.NEXT_PUBLIC_GA_TRACKING_ID || 'G-D80K1E0GN4'
