import { Falsy } from 'utility-types'

export const uniqueValue = <T>(
  array: T[],
  key: keyof T,
): NonNullable<T[keyof T]>[] =>
  // @ts-ignore
  Array.from(new Set(array.map((elem) => elem[key]).filter(Boolean)))

export const filterByType = <T>(
  array: any[],
  typeguard: (arg: any) => arg is T,
): T[] => array.filter(typeguard)

export const filterFalsy = <T>(array: (T | Falsy)[]): T[] =>
  array.filter(Boolean) as T[]

export const spliceOne = <T>(
  array: T[],
  index: number,
  changes: Partial<T>,
) => [
  ...array.slice(0, Math.max(index, 0)),
  { ...array[index], ...changes },
  ...array.slice(index + 1),
]

export const spliceArrayOne = <T>(
  array: T[][],
  index: number,
  changes: T[],
) => [...array.slice(0, Math.max(index, 0)), changes, ...array.slice(index + 1)]
