import {
  ErrorMessageModal,
  ErrorMessageModalProps,
} from 'src/components/Modal/ErrorMessageModal'
import {
  MessageModal,
  MessageModalProps,
} from 'src/components/Modal/MessageModal'
import useSWR from 'swr'

export { ModalPortal, useMessageModal, useErrorMessageModal }

const ERROR_MESSAGE_MODAL_KEY = 'modal/error-message'
const useErrorMessageModal = () => {
  const { mutate } = useSWR(ERROR_MESSAGE_MODAL_KEY, null)
  const open = (props: Omit<ErrorMessageModalProps, 'close'>) => mutate(props)
  const close = () => mutate(null, true)
  return { open, close }
}
const ErrorMessageModalGlobal = () => {
  const { data, mutate } = useSWR(ERROR_MESSAGE_MODAL_KEY, null)
  const close = () => mutate(null, true)
  return <>{data && <ErrorMessageModal {...data} close={close} />}</>
}

const MESSAGE_MODAL_KEY = 'modal/message'
const useMessageModal = () => {
  const { mutate } = useSWR(MESSAGE_MODAL_KEY, null)
  const open = (props: Omit<MessageModalProps, 'close'>) => mutate(props)
  return { open }
}
const MessageModalGlobal = () => {
  const { data, mutate } = useSWR(MESSAGE_MODAL_KEY, null)
  const close = () => mutate(null, true)
  return <>{data && <MessageModal {...data} close={close} />}</>
}

const ModalPortal = () => {
  return (
    <>
      <MessageModalGlobal />
      <ErrorMessageModalGlobal />
    </>
  )
}
