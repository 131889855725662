import Router from 'next/router'
import { VFC } from 'react'
import { MessageModal } from './MessageModal'

export type ErrorMessageModalProps = {
  title?: string
  message?: string
  label?: string
  close: VoidFunction
}
export const ErrorMessageModal: VFC<ErrorMessageModalProps> = ({
  title,
  message = 'エラーが発生しました。再度お試し頂くか、運営までお問い合わせください。',
  label = 'OK',
  close,
}) => (
  <MessageModal
    title={title}
    message={message}
    buttonLabel={label}
    close={close}
  />
)

export const CrashMessageModal: VFC<{ close: VoidFunction }> = ({ close }) => (
  <MessageModal
    title="エラーが発生しました"
    message="前のページに戻って再度お試し頂くか、運営者までお問い合わせください。"
    close={() => {
      Router.back()
      close()
    }}
    buttonLabel="前のページに戻る"
  />
)
