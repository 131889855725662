// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

if (process.env.NODE_ENV !== 'development')
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://e86d0f3fc11c4e33b75a073cd85db05c@o401927.ingest.sentry.io/6050542',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.5,
    environment: process.env.NEXT_PUBLIC_HOSTNAME || 'development',
    ignoreErrors: ['No current user'],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
