import { BASE_PATH } from 'src/utils/routes'

const IMAGE_BASE_PATH = `${BASE_PATH}/assets/images`

export const CLOSE_ICON = `${IMAGE_BASE_PATH}/close-bt@3x-min.png`
export const CLOSE_WITH_CIRCLE_ICON = `${IMAGE_BASE_PATH}/close_with_circle.png`
export const DEFAULT_IMAGE = `${IMAGE_BASE_PATH}/default_story.jpg`
export const FOOTER_ARROW = `${IMAGE_BASE_PATH}/arrow_footer.png`
export const PUBLISHED = `${IMAGE_BASE_PATH}/published.jpg`
export const MAINTENACE = `${IMAGE_BASE_PATH}/maintenance.png`

export const CONTRIBUTION_ARROW = `${IMAGE_BASE_PATH}/contribution_arrow.png`
export const CONTRIBUTION_BUDGET = `${IMAGE_BASE_PATH}/contribution_budget.png`
export const CONTRIBUTION_BUY = `${IMAGE_BASE_PATH}/contribution_buy.png`
export const CONTRIBUTION_CALENDAR = `${IMAGE_BASE_PATH}/contribution_calendar.png`
export const CONTRIBUTION_CONSULTATION = `${IMAGE_BASE_PATH}/contribution_consultation.png`
export const CONTRIBUTION_INTRODUCTION_TIMING = `${IMAGE_BASE_PATH}/contribution_introduction_timing.png`
export const CONTRIBUTION_PR = `${IMAGE_BASE_PATH}/contribution_pr.png`
export const CONTRIBUTION_SELECT_ARROW = `${IMAGE_BASE_PATH}/contribution_select_arrow.png`
export const CONTRIBUTION_USER = `${IMAGE_BASE_PATH}/contribution_user.png`
export const CONTRIBUTION_ANONYMOUS_MOSAIC = `${IMAGE_BASE_PATH}/contribution_anonymous_mosaic.png`
export const CONTRIBUTION_USER_CIRCLE = `${IMAGE_BASE_PATH}/contribution_user_circle.png`

export const UPDATE_NEEDS = `${IMAGE_BASE_PATH}/needs_update_popup.png`

export const ATTENTION_1 = `${IMAGE_BASE_PATH}/attention_message_icon1.png`
export const ATTENTION_2 = `${IMAGE_BASE_PATH}/attention_message_icon2.png`
export const ATTENTION_3 = `${IMAGE_BASE_PATH}/attention_message_icon3.png`

export const APP_STORE_QRCODE = `${IMAGE_BASE_PATH}/mobile-app-qr-code-for-ios.png`
export const PLAY_STORE_QRCODE = `${IMAGE_BASE_PATH}/mobile-app-qr-code-for-android.png`
export const APP_MOCK = `${IMAGE_BASE_PATH}/mobile-app-mockup-sp.png`

export const PAID_MEMBER_TOP_BACKGROUND_WIDE =
  'https://assets.onlystory.co.jp/assets/members/fv-bg@2x-min-fb46426b01cfb14013c7e024b3c8e82fa30622ac102dc71b0ebb8ba4c407163f.png'
export const PAID_MEMBER_TOP_BACKGROUND_NARROW =
  'https://assets.onlystory.co.jp/assets/members/sp/fv-bg-sp@2x-min-3eaa4007f15d6c111c8f1070a2e6c226f962138b4d9df897b5c007e404d52220.png'
export const PAID_MEMBER_TOP_LOGO =
  'https://assets.onlystory.co.jp/assets/members/fv-rogo@2x-min-6ba0163f063c5c2d9984aaeb7766ecdb04c1e600fb56a5bc6c31be2d85488038.png'
export const DOWN_ARROW =
  'https://assets.onlystory.co.jp/assets/members/icon/fv-arrow-sp@3x-min-d896cb133f643c761e1040bf6b742daba5e0e27907d46bf3db55db660f754b66.png'

export const EVENT_BACKGROUND_LEFT = `${IMAGE_BASE_PATH}/event_left_bg.png`
export const EVENT_BACKGROUND_RIGHT = `${IMAGE_BASE_PATH}/event_right_bg.png`

export const INCENTIVE_1 = `${IMAGE_BASE_PATH}/incentive_1.png`
export const INCENTIVE_2 = `${IMAGE_BASE_PATH}/incentive_2.png`

export const PROFILE_NEEDS = `${IMAGE_BASE_PATH}/profile_needs.png`
export const PROFILE_NOTIFICATION = `${IMAGE_BASE_PATH}/profile_notification.png`
export const PROFILE_PASSWORD = `${IMAGE_BASE_PATH}/profile_password.png`
export const PROFILE_PERSONA = `${IMAGE_BASE_PATH}/profile_persona.png`
export const PROFILE_SCHEDULE = `${IMAGE_BASE_PATH}/profile_schedule.png`
export const PROFILE_STORY = `${IMAGE_BASE_PATH}/profile_story.png`
export const PROFILE_USER = `${IMAGE_BASE_PATH}/profile_user.png`
export const PROFILE_SCHEDULE_URL_EXAMPLE = `${IMAGE_BASE_PATH}/schedule_url_detail.png`

export const PURCHASE_DETAIL_1 = `${IMAGE_BASE_PATH}/purchase_detail_1.png`
export const PURCHASE_DETAIL_2 = `${IMAGE_BASE_PATH}/purchase_detail_2.png`
export const PURCHASE_COMPLETED = `${IMAGE_BASE_PATH}/purchase_completed.png`

export const SEND_ICON = `${IMAGE_BASE_PATH}/send_icon.png`
export const SEND_ICON_REVERSE = `${IMAGE_BASE_PATH}/send_icon_reverse.png`

export const FACEBOOK = `${IMAGE_BASE_PATH}/facebook.png`
export const FACEBOOK_2 = `${IMAGE_BASE_PATH}/facebook_2.png`

export const JUDGE_COPLITE = `${IMAGE_BASE_PATH}/judge_coplite.png`

export const PROHIBITION = `${IMAGE_BASE_PATH}/prohibition.png`
export const HOW_TO = `${IMAGE_BASE_PATH}/how_to.png`

export const FEEDBACK_EXCELLENT_ICON = `${IMAGE_BASE_PATH}/icon_feedback_excellent.png`
export const FEEDBACK_GOOD_ICON = `${IMAGE_BASE_PATH}/icon_feedback_good.png`
export const FEEDBACK_FAIR_ICON = `${IMAGE_BASE_PATH}/icon_feedback_fair.png`
export const FEEDBACK_PENDING_ICON = `${IMAGE_BASE_PATH}/icon_feedback_pending.png`
export const FEEDBACK_NOT_INTEREST_ICON = `${IMAGE_BASE_PATH}/icon_feedback_not_interest.png`
export const TIMELINE_BATCH_MESSAGE = `${IMAGE_BASE_PATH}/timeline_batch_message.png`

export const MESSAGE_FILTER_SP = `${IMAGE_BASE_PATH}/message_filter_sp.png`
export const MESSAGE_SELECTED_FILTER_SP = `${IMAGE_BASE_PATH}/message_selected_filter_sp.png`
export const MESSAGE_ROOM_TYPE_SP = `${IMAGE_BASE_PATH}/message_room_type_sp.png`
export const MESSAGE_SELECTED_ROOM_TYPE_SP = `${IMAGE_BASE_PATH}/message_selected_room_type_sp.png`
