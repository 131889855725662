export const TOP = '/'
export const STORIES = '/stories'
export const STORIES_NEW = `${STORIES}/?order=new`
export const STORIES_POPULAR = `${STORIES}/?order=rank`
export const STORIES_FEATURE = `${STORIES}/?order=feature`
export const COMPANY = '/pages/company_new'
export const TERMS = '/terms'
export const PRIVACY = '/privacy'
export const SECURITY = '/pages/secuity'
export const ARTICLES = '/articles'

export const BOARD = '/companies/messages/board'

export const NOTICES = '/companies/articles'

export const EDIT = '/companies/edit'
export const EDIT_STORYLIST = '/companies/edit-storylist'
export const EDIT_FIXED_MESSAGE = '/user_fixed_message'

export const CONTACT = '/contacts/new'

export const REFERRER_MESSAGE_GROUP = '/companies/message_group_index'

export const membersArticlePage = (id: number) => `/companies/articles/${id}`

export const GET_NEEDS_CSV = '/companies/needs.csv'

export const companyMessageTargetUrl = (companyId: number) => `
  /companies/messages/${companyId}`

export const companyDetail = (
  id: number,
  canMessage: boolean,
  canRequest: boolean,
) =>
  `/companies/detail?id=${id}&can_message=${canMessage}&can_request=${canRequest}`
