export const BASE_PATH = '/member'
export const NOT_FOUND = '/404'
export const SORRY = '/sorry'
export const FACEBOOK_DELETION = '/facebook_deletion'

export const SIGN_IN = '/sign_in'
export const FORGOT_PASSWORD = '/auth/forgot_password'
export const AUTH_FACEBOOK = '/auth/facebook'

export const APPLY_NOW = '/apply/apply_now'
export const FIRST_SEMINAR = '/apply/first_seminar'
export const FIRST_SETTING_FORM = `/apply/first_setting_view`

export const MYPAGE = '/companies'
export const CONTRIBUTIONS = '/contributions'
export const CONTRIBUTIONS_NEW = '/companies/company_contributions/new'
export const MY_CONTRIBUTIONS = '/companies/company_contributions'

export const contributionDetail = (id: number) =>
  `/contributions/detail?id=${id}`
export const editContribution = (id: number) =>
  `/companies/company_contributions/edit?id=${id}`

export const EDIT_PROFILE = '/companies/edit-profile'
export const EDIT_COMPANY = '/companies/edit-company'
export const EDIT_SETTLER = '/companies/edit-settler'
export const EDIT_NEEDS = '/companies/company_self_needs_edit'
export const EDIT_SCHEDULE_URL = '/companies/schedule_url_edit'

export const EDIT_PASSWORD = '/companies/edit-passwords'

export const MESSAGES = '/companies/messages'
export const messageUrl = (roomUniqueId: string) =>
  `/companies/messages?room_id=${roomUniqueId}`

export const greet = (roomUniqueId: string) =>
  `/companies/messages/greet?room_id=${roomUniqueId}`

export const EVENTS = '/events'
export const eventDetail = (id: number) => `/events/detail?id=${id}`
export const USAGE_HISTORIES = '/companies/usage_histories'
export const EDIT_PERSONA = '/company_personas'
export const PURCHASE = '/companies/purchase'
export const RECOMMEND = '/companies/recommend'

export const NOTICE_CONFIGURATION = '/companies/notice-configuration'
export const SLACK_SETTINGS = '/companies/needs/slack_edit'
export const CHATWORK_SETTINGS = '/companies/needs/chatwork_edit'

export const KEEP = '/companies/keep'
export const NEEDS = '/companies/needs'

export const getNeedsUrl = (
  query: string,
  page: number = 1,
  scroll?: boolean,
) => {
  let url = `${NEEDS}?buy=${page}${query}`
  if (scroll) url = url + '#search-scroll-to'
  return url
}

export const getKeepsUrl = (page: number = 1) => {
  return `${KEEP}?page=${page}`
}

export const POLICY = '/policy'

export const REGISTER = '/register'

export const REGISTER_COMPLETE = '/register/complete'

export const MESSAGE_GUEST = '/message_guest'

export const FEEDBACKS = '/companies/feedbacks'

export const REQUEST_BOX = '/companies/request_boxes'
