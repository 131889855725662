import { ReactNode, VFC } from 'react'
import { asModal, ModalProps } from 'src/components/hoc/asModal'
import { orange, truewhite } from 'src/styles/colors'
import { fontWeightBold } from 'src/styles/font'
import styled from 'styled-components'

const Layout = styled.div<{
  borderRadius?: number
  textAlign?: string
  buttonLayout?: string
}>`
  background-color: ${truewhite};
  margin: 0 20px;
  padding: 20px 40px;
  max-width: 640px;
  white-space: pre-wrap;
  line-height: 1.4;
  ${({ textAlign = 'center' }) => textAlign && `text-align: ${textAlign};`};
  ${({ borderRadius = 16 }) =>
    borderRadius && `border-radius: ${borderRadius}px;`};

  h1 {
    font-size: 20px;
    font-weight: ${fontWeightBold};
  }

  div {
    ${({ buttonLayout = 'center' }) =>
      buttonLayout && `text-align: ${buttonLayout};`};
  }

  button {
    margin: 0 auto;
    padding: 10px 40px;
    background-color: ${orange};
    color: ${truewhite};
    font-weight: ${fontWeightBold};
    ${({ borderRadius = 16 }) =>
      borderRadius && `border-radius: ${borderRadius}px;`};
  }
  > * {
    margin: 20px 0;
  }
`

export type MessageModalProps = {
  title?: string
  content?: ReactNode
  message?: string
  buttonLabel?: string
  onClose?: VoidFunction
  backgroundColor?: string
  borderRadius?: number
  textAlign?: string
  buttonLayout?: string
}

const Message: VFC<ModalProps<MessageModalProps>> = ({
  close,
  title,
  content,
  message,
  buttonLabel = 'OK',
  onClose,
  backgroundColor,
  borderRadius,
  textAlign,
  buttonLayout,
}) => (
  <Layout
    borderRadius={borderRadius}
    textAlign={textAlign}
    buttonLayout={buttonLayout}
  >
    <h1>{title}</h1>
    {content}
    <p>{message}</p>
    <div>
      <button
        style={backgroundColor ? { backgroundColor } : undefined}
        type="button"
        onClick={() => {
          onClose && onClose()
          close()
        }}
      >
        {buttonLabel}
      </button>
    </div>
  </Layout>
)

export const MessageModal = asModal(Message, {
  inescapable: true,
})

export const MessageModalWithClose = asModal(Message, {
  inescapable: false,
})
